/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useEffect, useState } from 'react';
import mbpLogger from 'mbp-logger';
import {
    array, bool, func, object, shape, string,
} from 'prop-types';
import { useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { getBrand } from '../../../../../../pages/Account/state/ducks/Common/Common-Selectors';
import { GRAPHQL_ENV } from '../../../../../../gql';
import findContent from '../../../../../../gql/queries/findContent';
import GenericBanner from './GenericBanner';
import useSSRMediaQuery from '../../../../../../helpers/hooks/useSSRMediaQuery';
import PriceRange from '../../../../GraphqlCategoryPage/Partials/GraphqlProductContainer/Partials/GraphqlSimpleProduct/DesktopSimpleProduct/PriceRange';
import PriceBreakDownUi from '../../../../GraphqlCategoryPage/Partials/GraphqlProductContainer/Partials/GraphqlSimpleProduct/DesktopSimpleProduct/PriceBreakDownUi';
import { getFeatureFlags } from '../../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { withAuth } from '../../../../../../../state/ducks/Member/ducks/Auth/Plugin/auth';
import getPriceFromEngine from '../../../../../../../apis/product-apis/priceEngine';
import { getEnterpriseId } from '../../../../../../../state/ducks/Member/ducks/EnterpriseId/EnterpriseId-Selectors';
import { getPriceArrays } from '../../../../../../helpers/DynamicPricing/getPriceArrays';
import { getIsAuthenticatedStatus, getIsPassportUserLoggedIn } from '../../../../../../../state/ducks/Member/ducks/Common/Common-Selectors';
import { getPassportData } from '../../../../../../../state/ducks/Passport/Passport-Selectors';
import validatePassport from '../../../../../../helpers/validatePassport';
import { getPassportSubscriptionStatus } from '../../../../../../../state/ducks/Common/Common-Selectors';

const ProductsList = ({
    classes, subCollectionsData, id, seed, enableToggle, trackEvent, banners,
}) => {
    const device = useSSRMediaQuery();
    const brand = useSelector((state) => getBrand(state));
    const enterpriseId = useSelector(getEnterpriseId);
    const featureFlags = useSelector(getFeatureFlags);
    const [newPriceData, setNewPriceData] = useState(null);
    const isDynamicPriceEnabled = featureFlags['is-dynamic-pricing-enabled'];

    const [dynamicPriceLoading, setDynamicPriceLoading] = useState(!!isDynamicPriceEnabled);
    const isPassportUser = useSelector(getIsPassportUserLoggedIn);
    const isAuthenticated = useSelector(getIsAuthenticatedStatus);
    const passportData = useSelector(getPassportData);
    const isPassportMember = validatePassport('isActivePassportUser', passportData);
    const whichCollectionPageRestructure = featureFlags['which-collection-page-restructure'];
    const collectionRestructureVariantB = whichCollectionPageRestructure?.toLowerCase() === 'variantb';

    if (!whichCollectionPageRestructure) {
        return <></>;
    }
    const isPassportAdded = useSelector(getPassportSubscriptionStatus);
    let customerTypeValue = [];
    if ((isPassportMember && isPassportUser) || isPassportAdded) {
        customerTypeValue = [{
            name: 'CUSTOMER_TYPE',
            value: 'Passport',
        }];
    } else if (isAuthenticated) {
        customerTypeValue = [{
            name: 'CUSTOMER_TYPE',
            value: 'Registered',
        }];
    }
    const allBannerUIDs = (banners || []).map((banner) => banner?.uid) || [];
    const contentType = 'collection_banners';
    const query = `\\"uid\\":{\\"$in\\":[\\"${allBannerUIDs.join('\\",\\"')}\\"]}`;
    const findContentOptions = {
        brand: brand['domain-name'],
        contentType,
        GRAPHQL_ENV,
        query,
    };
    const BANNER_QUERY = findContent(findContentOptions);
    const { loading, error, data: bannerData } = useQuery(BANNER_QUERY);

    const productsIds = [];
    subCollectionsData.forEach((item) => {
        item.products.forEach((product) => {
            productsIds.push({
                partNumber: product.id,
            });
        });
    });

    useEffect(() => {
        async function fetchPriceFromEngine() {
            if (!isDynamicPriceEnabled) {
                setDynamicPriceLoading(false);
                return;
            }

            if (!enterpriseId) {
                setDynamicPriceLoading(false);
                return;
            }

            const payload = {
                enterpriseId,
                products: productsIds,
                variants: customerTypeValue,
            };

            getPriceFromEngine({}, null, payload, featureFlags['is-price-engine-batching-enabled'])
                .then(({ data }) => {
                    setDynamicPriceLoading(false);
                    setNewPriceData(data?.products);
                })
                .catch((ex) => {
                    mbpLogger.logError({
                        appName: process.env.npm_package_name,
                        jsError: ex,
                        message: `ProductsList: fetch prices failed...${ex.message}`,
                        enterpriseId,
                    });
                    setDynamicPriceLoading(false);
                });
        }

        fetchPriceFromEngine();
    }, [
        featureFlags,
        enterpriseId,
        isPassportAdded,
    ]);

    if (loading && !error && !bannerData) {
        return <></>;
    }
    const isMobile = device === 'mobile';
    const inCollectionBanners = bannerData?.findContent?.content?.entries || [];
    const getBannerAt = (position) => (inCollectionBanners || []).filter((banner) => (isMobile ? banner?.banner?.[0]?.positioning?.mobile?.position : banner?.banner?.[0]?.positioning?.desktop?.position) === position) || [];
    const renderBanner = (bannerList, list) => (bannerList || []).map((item) => {
        const banner = item?.banner?.[0] || {};
        return (
            <>
                <Grid key={seed(list)} item xs={(isMobile ? 6 : 3) * (isMobile ? banner?.positioning?.mobile?.width : banner?.positioning?.desktop?.width) || 1} className={classes.gridProductContainer}>
                    <div className={classes.productContainer}>
                        <GenericBanner
                            data={{
                                trackable_link: {
                                    link: {
                                        title: banner?.banner_link?.link?.title,
                                        href: banner?.banner_link?.link?.href,
                                    },
                                    event_action: banner?.banner_link?.tracking_event_action,
                                    event_category: banner?.banner_link?.tracking_event_category,
                                    event_label: banner?.banner_link?.tracking_event_label,
                                },
                                mobile_image: {
                                    url: banner?.banner_image_mobile?.url,
                                },
                                desktop_image: {
                                    url: banner?.banner_image?.url,
                                },
                            }}
                        />
                    </div>
                </Grid>
            </>
        );
    });

    const getPrices = (priceData) => {
        const priceArrays = getPriceArrays(priceData);
        if (!priceArrays?.sale?.length && !priceArrays?.retail?.length) {
            return null;
        }
        return priceArrays;
    };

    return subCollectionsData?.map((data) => (
        data.id === id
            ? (
                <Grid key={seed(data)} container>
                    {data?.products?.map((list, index) => {
                        const banner = getBannerAt(index + 1) || [];
                        const productItem = (
                            <Grid key={seed(list)} item xs={(isMobile ? 6 : 3)} className={!isMobile ? classes.gridProductContainer : ''}>
                                <div className={classes.productContainer}>
                                    <Link
                                        to={list?.seo?.url}
                                        onClick={() => trackEvent({
                                            eventCategory: 'Subscriptions',
                                            eventAction: 'Top Sellers',
                                            eventLabel: list?.name,
                                        })}
                                    >
                                        <img src={`${list?.image?.path}${list?.image?.name}x.jpg`} alt={list?.name} />
                                        <p>{list?.name}</p>
                                        <PriceRange
                                            skuPriceRange={isDynamicPriceEnabled && (newPriceData || []).find((item) => item.partNumber === list.id)?.prices ? getPrices((newPriceData || []).find((item) => item.partNumber === list.id)?.prices) : list?.skuPriceRange}
                                            displayPrice={!dynamicPriceLoading}
                                            isMobile={isMobile}
                                            colorMatchTitle
                                            noFromText
                                            collectionRestructureEnabled={collectionRestructureVariantB}
                                        />
                                    </Link>
                                    <PriceBreakDownUi dynamicPriceData={(newPriceData || []).find((item) => item.partNumber === list.id)} />
                                    {enableToggle && <div className={classes.productLink}><Link to={list?.seo?.url}>Quick View</Link></div>}
                                </div>
                            </Grid>
                        );
                        if (banner?.length > 0) {
                            return (
                                <>
                                    {renderBanner(banner, list)}
                                    {productItem}
                                </>
                            );
                        }
                        return productItem;
                    })}

                </Grid>
            )
            : <></>
    ));
};

ProductsList.propTypes = {
    classes: object.isRequired,
    subCollectionsData: array.isRequired,
    id: string.isRequired,
    seed: func.isRequired,
    enableToggle: bool.isRequired,
    trackEvent: func.isRequired,
    banners: array.isRequired,
};

export default withAuth(ProductsList);
